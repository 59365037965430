<template>
  <div>
    <a-button-group>
      <a-button-group size="small">
        <a-button v-if="enablePurchaseManage" @click="purchase"> 采购 </a-button>
        <a-button @click="internalProductionModalVisible = true"> 自产 </a-button>
        <a-button @click="outsourcedProductionModalVisible = true"> 外协加工 </a-button>
        <a-button type="primary" @click="openStockOutModal">领料</a-button>
        <a-button type="primary" @click="stockReturnModalVisible = true">退料</a-button>
      </a-button-group>
    </a-button-group>

    <a-modal
      v-model="stockOutModalVisible"
      title="原料出库"
      :confirmLoading="stockOutLoading"
      :maskClosable="false"
      @cancel="stockOutCancel"
      @ok="stockOutComfirm"
    >
      <a-form-model
        ref="stockOutForm"
        :model="stockOutForm"
        :rules="stockOutRules"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 16 }"
      >
        <a-form-model-item prop="stock_out_quantity" label="领料数量">
          <a-input-number v-model="stockOutForm.stock_out_quantity" style="width: 100%" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <a-modal
      v-model="stockReturnModalVisible"
      title="原料退库"
      :confirmLoading="stockReturnLoading"
      :maskClosable="false"
      @cancel="stockReturnCancel"
      @ok="stockReturnComfirm"
    >
      <a-form-model
        ref="stockReturnForm"
        :model="stockReturnForm"
        :rules="stockReturnRules"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 16 }"
      >
        <a-form-model-item prop="stock_return_quantity" label="退料数量">
          <a-input-number v-model="stockReturnForm.stock_return_quantity" style="width: 100%" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <InternalProductionModal
      v-model="internalProductionModalVisible"
      :productionOrderItem="productionOrderItem"
      :bomItem="bomItem"
      :goodsItem="goodsItem"
    />
    <OutsourcedProductionModal
      v-model="outsourcedProductionModalVisible"
      :productionOrderItem="productionOrderItem"
      :bomItem="bomItem"
      :goodsItem="goodsItem"
    />
  </div>
</template>

<script>
import { productionOrderStockOut, productionOrderStockReturn } from "@/api/production";

export default {
  components: {
    InternalProductionModal: () => import("./InternalProductionModal"),
    OutsourcedProductionModal: () => import("./OutsourcedProductionModal"),
  },
  props: ["productionOrderItem", "goodsItem", "bomItem"],
  data() {
    return {
      stockOutForm: { stock_out_quantity: undefined },
      stockOutRules: { stock_out_quantity: [{ required: true, message: "请输入出库数量", trigger: "change" }] },
      stockOutLoading: false,
      stockOutModalVisible: false,

      stockReturnForm: { stock_return_quantity: undefined },
      stockReturnRules: { stock_return_quantity: [{ required: true, message: "请输入退库数量", trigger: "change" }] },
      stockReturnLoading: false,
      stockReturnModalVisible: false,
      internalProductionModalVisible: false,
      outsourcedProductionModalVisible: false,
    };
  },
  computed: {
    enablePurchaseManage() {
      return this.$store.state.user.enablePurchaseManage;
    },
  },
  methods: {
    stockOutCancel() {
      this.stockOutModalVisible = false;
      this.$refs.stockOutForm.resetFields();
    },
    stockOutComfirm() {
      this.$refs.stockOutForm.validate((valid) => {
        if (valid) {
          this.stockOutLoading = true;
          productionOrderStockOut({ id: this.productionOrderItem.id, goods: this.goodsItem.id, ...this.stockOutForm })
            .then(() => {
              this.$message.success("生产领料已申请");
              this.stockOutCancel();
            })
            .finally(() => {
              this.stockOutLoading = false;
            });
        }
      });
    },
    stockReturnCancel() {
      this.stockReturnModalVisible = false;
      this.$refs.stockReturnForm.resetFields();
    },
    stockReturnComfirm() {
      this.$refs.stockReturnForm.validate((valid) => {
        if (valid) {
          this.stockReturnLoading = true;
          productionOrderStockReturn({ id: this.productionOrderItem.id, goods: this.goodsItem.id, ...this.stockReturnForm })
            .then(() => {
              this.$message.success("原料退库已申请");
              this.stockReturnCancel();
            })
            .finally(() => {
              this.stockReturnLoading = false;
            });
        }
      });
    },
    purchase() {
      this.$router.push({
        name: "purchase_requisition_order_create",
        params: { data: [{ goods: this.bomItem.goods, quantity: this.bomItem.deficit_quantity }] },
      });
    },
    openStockOutModal() {
      this.stockOutModalVisible = true;
      this.stockOutForm.stock_out_quantity = this.productionOrderItem.total_quantity * this.bomItem.quantity;
    },
  },
};
</script>

<style scoped></style>
